<template>
  <div class="supper">
    <div class="holder">
      <div class="login-body">
        <div class="login-wrap">
          <div class="">
            <div class="logo_holder">
              <img src="../assets/images/logo-yellow.svg" alt="logo" />
            </div>
            <hr />
          </div>
          <div class="container">
            <div class="my-card-body">
              <div class="login-header">
                <h1>Welcome</h1>
                <p>Login to continue</p>
              </div>
              <form>
                <div class="form-group email-group">
                  <label for="email">Email Address</label>
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    placeholder="Email address"
                    autocomplete="on"
                    v-model="email"
                    name="email"
                  />
                  <i
                    class="fa fa-envelope email-icon"
                    id="passwordVisibility"
                    aria-hidden="true"
                  ></i>
                </div>
                <div class="form-group password-group">
                  <label for="password">Password</label>
                  <input
                    type="password"
                    class="form-control"
                    id="password"
                    placeholder="Password"
                    name="password"
                    v-model="password"
                    autocomplete="on"
                  />
                  <i
                    class="fa fa-lock password-icon"
                    id="passwordVisibility"
                    aria-hidden="true"
                    @click="toggleLock()"
                  ></i>
                </div>
                <div v-if="errors.length">
                  <p class="errorHeader">Please correct the error(s):</p>
                  <ul class="error-ul">
                    <li
                      v-for="error in errors"
                      :key="error.error"
                      class="errorMessage"
                    >
                      {{ error }}
                    </li>
                  </ul>
                </div>

                <div class="form-group">
                  <button
                    @click.prevent="login()"
                    class="btn login-btn btn-block"
                    v-bind:class="{ active: loader }"
                  >
                    LOGIN
                    <!-- <i class="fa fa-spinner fa-spin  ml-3 f-12  btn-loader" v-if="loader"></i> -->
                    <i
                      class="fa fa-spinner fa-spin  ml-3 f-12  btn-loader"
                      v-if="loader"
                    ></i>
                  </button>
                </div>
                <div class="form-group d-none">
                  <div class="or">
                    <hr />
                    <span>or</span>
                    <hr />
                  </div>
                </div>
                <div class="form-group d-none">
                  <button class="btn btn-block google_btn">
                    <img src="../assets/images/google-G.svg" alt="google" />
                    Continue with Google
                  </button>
                </div>
                <div class="no-account-password">
                  <p>
                    <router-link class="register-router" to="/signup">
                      Don't have an account?
                      <span class="register-span">Register</span>
                    </router-link>
                  </p>
                  <p>
                    <router-link class="register-router" to="/forgotpassword"
                      >Forgot password?</router-link
                    >
                  </p>
                </div>
              </form>
            </div>
          </div>

          <!-- <p class="" data-toggle="modal" data-target="#user_type">Modal23</p> -->
        </div>
        <!-- <div class="whatsapp-dialer-icon-holder">
          <img src="../assets/images/whatsapp-icon.svg" alt="whatsapp" />
          <img src="../assets/images/phone-handle.svg" alt="call us" />
        </div> -->
      </div>
      <!-- Footter bigins -->

      <!-- User Type modal -->
      <!-- Modal -->
      <div
        class="modal fade"
        id="user_type"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content" style="margin-bottom: 40px;">
            <div class="user__switch">
              <span
                @click.prevent="switchUser('landlord')"
                :class="isActive === 'landlord' ? 'active' : 'inactive'"
                >Landlord</span
              >
              <span
                @click.prevent="switchUser('tenant')"
                :class="isActive === 'tenant' ? 'active' : 'inactive'"
                >Tenant</span
              >
            </div>

            <div
              class="custom-control custom-checkbox form-check"
              :class="loggedIn === 'remember' ? 'paddingClass' : ''"
            >
              <input
                @change="userPrefrence()"
                type="checkbox"
                class="custom-control-input"
                id="dont"
                name="dn"
                true-value="remember"
                false-value="noremember"
                v-model="loggedIn"
              />
              <label class="custom-control-label checkboxlabel" for="dont"
                >Remember my preference?</label
              >
              <p>
                <i
                  class="fa fa-spinner fa-spin  ml-3 f-12  btn-loader"
                  v-if="loggerloader"
                ></i>
              </p>
            </div>
            <div class="modal__btn" v-if="loggedIn == 'noremember'">
              <button @click="routeLogger()" class="btn">CONTINUE</button>
            </div>
          </div>
        </div>
      </div>
      <!-- User Type modal -->

      <!-- Footer ends -->
    </div>
    <!-- <div [innerHTML]="status"></div> -->
    <!-- Footter bigins -->
    <Footer></Footer>
    <!-- Footer ends -->
  </div>
</template>

<script>
import Footer from "./FooterMain2.vue";
// import AuthNavbar from "./AuthNavbar";
import $ from "jquery";
import { Service } from "../store/service";
const Api = new Service();

export default {
  name: "Login",
  components: {
    // Header,
    Footer
    // AuthNavbar,
  },
  data() {
    return {
      loader: false,
      loggerloader: false,
      errors: [],
      email: "",
      password: "",
      confirmPassword: "",
      isActive: "landlord",
      loggedIn: "noremember",
      user: ""
    };
  },
  // mounted() {},
  methods: {
    switchUser(user) {
      this.isActive = user;
    },
    routeLogger() {
      if (this.isActive === "landlord") {
        localStorage.setItem("shelta-loger", "Landlord");
        $("#user_type").modal("hide");
        this.$router.push("/dashboard/landlord");
      }
      if (this.isActive === "tenant") {
        localStorage.setItem("shelta-loger", "Tenant");
        $("#user_type").modal("hide");
        this.$router.push("/dashboard/tenant");
      }
    },
    login() {
      this.loader = true;
      this.errors = [];
      if (this.email === "") {
        this.errors.push("Email is required!");
        this.loader = false;
      }
      if (this.password === "") {
        this.errors.push("Password is required!");
        this.loader = false;
      } else {
        Api.postRequestNoAuth("login", {
          email: this.email,
          password: this.password
        }).then(res => {
          // console.log("Login Response", res);
          this.loader = false;
          //  this.status = Api.successNotification('Success');
          // return this.$store.commit("setApiSuccess", "Success");
          if (res.data.success) {
            localStorage.setItem("shelta", res.data.token);
            localStorage.setItem("shelta-user", JSON.stringify(res.data.user));
            this.user = res.data.user;
            this.$cookies.set(
              "shelta-c",
              res.data.token,
              Infinity,
              "/",
              "myshelta.com"
            );
            // To get cookies use the bellow code on the next commmented line
            // $cookies.get("shelta-c");
            // console.log('Cookies', $cookies.get("shelta-c"));

            if (
              res.data.user.login_as === "" ||
              res.data.user.login_as === null
            ) {
              $("#user_type").modal("show");
            } else {
              if (res.data.user.login_as === "landlord") {
                localStorage.setItem("shelta-loger", "Landlord");
                this.$router.push("/dashboard/landlord");
              }
              if (res.data.user.login_as === "tenant") {
                localStorage.setItem("shelta-loger", "Tenant");
                this.$router.push("/dashboard/tenant");
              }
            }
            // this.$router.push("/dashboard/shelta");
            // this.$router.push(this.$router.currentRoute.query.redirect);
            // return this.$store.commit("setApiSuccess", "Success");
          } else if (res.data.error) {
            return this.$store.commit("setApiFailed", res.data.error);
          }
        });
      }
    },
    userPrefrence() {
      if (this.loggedIn === "remember") {
        const id = this.user.id;
        this.loggerloader = true;
        if (localStorage.getItem('shelta')) {
          Api.postRequestNoAuth(`updateloginas`, {
            login_as: this.isActive,
            userid: id
          })
            .then(res => {
              // console.log("logger res", res);
              this.loggerloader = false;
              if (res.data.success) {
                return this.routeLogger();
              }
              if (res.data.error) {
                this.loggerloader = false;
                this.routeLogger();
                return this.$store.commit(
                  "setApiFailed",
                  "Could not save prefrence"
                );
              }
              if (res.message == "Unauthenticated.") {
                this.loggerloader = false;
                return this.$store.commit("setApiFailed", "Not Authenticated");
              }
            })
            .catch(err => {
              this.loggerloader = false;
              console.log("err:", err);
            });
        } else {
          this.loggerloader = false;
          return this.$store.commit("setApiFailed", "User Not Authenticated");
        }
      } else {
        // console.log("loggerIg", this.loggedIn);
        this.routeLogger();
      }
    },

    // Password visibilty
    toggleLock() {
      const element = document.querySelector(".password-icon");
      this.shown = !this.shown;
      if (this.shown) {
        document.getElementsByName("password")[0].setAttribute("type", "text");
        element.classList.remove("fa-lock");
        element.classList.add("fa-unlock");
      } else {
        document
          .getElementsByName("password")[0]
          .setAttribute("type", "password");
        element.classList.remove("fa-unlock");
        element.classList.add("fa-lock");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$yellow: #ffb100;
$black: #000000;
// $fontFamily: url("https://fonts.googleapis.com/css2?family=Gotham:wght@100&display=swap");
@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}

::-webkit-input-placeholder {
  /* Edge */
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 13px;
  color: rgba(0, 0, 0, 0.5);
}

:-ms-input-placeholder {
  /* Internet Explorer */
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 13px;
  color: rgba(0, 0, 0, 0.5);
}

::placeholder {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 13px;
  color: rgba(0, 0, 0, 0.5);
}

.logo_holder {
  margin-bottom: 1rem;
}
.supper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.holder {
  flex-grow: 1;
  min-height: 100%;
}

.logo-div {
  background: $primary;
  // clip-path: circle(50% at 50% 0);
  clip-path: circle(50% at 50% -20%);
  margin: auto;
  width: 12rem;
  height: 7rem;
  height: 4rem;
  position: relative;
}
.logo-div > img {
  width: 4.5rem;
  position: relative;
  top: 23%;
}

// .my-card-body {
//   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
// }
.login-header {
  h1 {
    margin-top: 30px;
    margin-bottom: 10px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #1e1e1e;
  }
  p {
    margin-top: 10px;
    margin-bottom: 30px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 125%;
    color: #1e1e1e;
  }
}

.login-body {
  position: relative;
  padding-top: 1rem;
}

.login-body > div {
  // width: 25rem;
  margin: auto;
}

form {
  display: inline-block;
  max-width: 540px;
  width: 100%;
}
.form-group {
  max-width: 540px;
  width: 100%;
  label {
    display: flex;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 124%;
    color: #57595a;
  }
}

.form-control {
  box-shadow: none;
  outline: 0;
  height: 44px;
  // width: 608px;
  // width: 100%;
}
.login-btn {
  background-color: $primary;
  color: #ffffff;
  text-transform: uppercase;
  height: 44px;
  box-shadow: none;
  outline: 0;
}

.or {
  display: flex;
  justify-content: center;
  align-items: center;
  hr:first-child {
    width: 100%;
    margin-right: 20px;
  }
  hr:last-child {
    width: 100%;
    margin-left: 20px;
  }
  span {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 29px;
    text-align: center;
    color: #1e1e1e;
  }
}

.google_btn {
  border: 1px solid #8e908e;
  border-radius: 6px;
  font-family: Gotham;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  height: 45px;
  // line-height: 124%;
  text-align: center;
  color: #1e1e1e;
  img {
    padding-right: 10px;
  }
}

.no-account-password {
  display: flex;
  justify-content: space-between;
  padding-top: 0.6rem;
}
.no-account-password {
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: #000000;
  }
}

.register-span {
  font-family: Gotham;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 12px;
  color: $primary;
}
.register-router {
  color: #000000;
  text-decoration: none;
}

// .login-wrap {
//   display: flex;
// }

.email-group {
  position: relative;
}
.email-icon {
  font-size: 0.9rem !important;
  position: absolute !important;
  bottom: 0% !important;
  right: 3% !important;
}

.password-group {
  position: relative;
}
.password-icon {
  font-size: 0.9rem !important;
  position: absolute !important;
  bottom: 0% !important;
  right: 3% !important;
}
.password-icon::before {
  cursor: pointer;
}

.whatsapp-dialer-icon-holder {
  position: relative;
}
.whatsapp-dialer-icon-holder > img:first-child {
  position: relative;
  bottom: 12rem;
  left: 40rem;
}
.whatsapp-dialer-icon-holder > img:nth-child(2) {
  position: relative;
  bottom: 8rem;
  left: 38.4rem;
}

.errorMessage {
  font-family: Gotham;
  font-weight: normal;
  color: #dc3545;
  font-size: 14px;
  list-style: none;
  text-align: center;
  padding-inline-start: 0;
}
.error-ul {
  padding-inline-start: 0;
}

.btn-loader {
  padding: 0 !important;
  font-size: 17px !important;
  margin-left: 2rem !important;
  box-shadow: none;
  outline: 0;
}

// Footer section begins
.footer-holder {
  position: relative;
}
.fa {
  padding: 20px;
  font-size: 30px;
  width: 13.55px !important;
  height: 13.55px !important;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  position: relative;
}

.fa:hover {
  opacity: 0.7;
}

.fa-facebook {
  // background: #3b5998;
  color: white;
  border: 1px solid #fff;
  border-radius: 3px;
}
.fa-facebook::before {
  position: absolute;
  left: 38%;
  top: 30%;
  font-size: 1.4rem;
}

.fa-twitter {
  // background: #55acee;
  color: white;
  border: 1px solid #fff;
  border-radius: 3px;
}
.fa-twitter::before {
  position: absolute;
  left: 30%;
  top: 28%;
  font-size: 1.4rem;
}

.fa-linkedin {
  // background: #007bb5;
  color: white;
  border: 1px solid #fff;
  border-radius: 3px;
}
.fa-linkedin::before {
  position: absolute;
  left: 30%;
  top: 25%;
  font-size: 1.4rem;
}

.fa-instagram {
  // background: #125688;
  border: 1px solid #fff;
  color: white;
  border-radius: 3px;
}
.fa-instagram::before {
  position: absolute;
  left: 30%;
  top: 25%;
  font-size: 1.4rem;
}
.clip-shape-div {
  clip-path: ellipse(43% 164% at 50% 164%);
  // clip-path: ellipse(35% 164% at 50% 165%);
  background: $primary;
  // position: relative;
}

.copy-right-text {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  align-items: center;
  text-align: center;
  letter-spacing: -0.05em;
  margin-bottom: 0;
  color: #ffffff;
  padding-bottom: 0.5rem;
}

.help-holder {
  position: absolute;
  right: 2%;
  bottom: 8%;
  width: 89px;
  height: 45px;
  background: $primary;
  border-radius: 22.5px;
}
// Footer section ends

// User type modal begin
.user__switch {
  padding: 44px 20px;
  margin-top: 2.5rem;
  span:first-child {
    padding: 16px 32px;
    // border: 0.5px solid rgba(0, 51, 234, 0.7);
    // color: $primary;
    cursor: pointer;
  }
  span:nth-child(2) {
    padding: 16px 32px;
    // border: 0.5px solid rgba(0, 51, 234, 0.7);
    border-radius: 0px 5px 5px 0px;
    // color: $primary;
    cursor: pointer;
  }
}
.paddingClass {
  margin-bottom: 50px !important;
}
.active {
  // padding: 16px 32px;
  background: $primary;
  border-radius: 5px 0px 0px 5px;
  color: #fff !important;
}
.inactive {
  // border: 0.5px solid rgba(0, 51, 234, 0.7);
  color: #767676;
  border-radius: 5px 0px 0px 5px;
  background: #f0f3fe;
}

.p-checkbox {
  text-align: left;
  label {
    text-align: left;
    font-family: Gotham;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 125%;
    padding-left: 10px;
    // color: $offblack;
  }
}
.checkboxlabel {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: -0.05em;
  color: #000000;
}
.checkboxlabel ::after {
  cursor: pointer !important;
}

.modal__btn {
  margin: 2rem 0 4rem 0;
  button {
    width: 250px;
    height: 45px;
    left: 595px;
    top: 339px;
    background: #3ec250;
    // background: $yellow;
    border-radius: 5px;
    color: $secondary;
  }
}
.form-check {
  // padding-right: 5rem;
}
input[type="checkbox"] {
  width: 18px;
  height: 18px;
  margin-top: 0;
}
// User type modal ends

@media only screen and (max-width: 300px) {
  .no-account-password {
    display: block;
  }
}

@media screen and (min-device-width: 320px) and (max-device-width: 640px) and (-webkit-min-device-pixel-ratio: 1) {
  // .login-body {
  //   padding-top: 4rem;
  // }
  .whatsapp-dialer-icon-holder {
    // position: relative;
    // right: 22%;

    right: 45%;
    justify-content: center;
    margin-bottom: 2rem;
    padding-top: 0.6rem;
  }
  .no-account-password {
    display: block;
  }
  .whatsapp-dialer-icon-holder > img:first-child {
    bottom: 1rem;
    left: 7px;
  }
  .whatsapp-dialer-icon-holder > img:nth-child(2) {
    bottom: 1rem;
    left: 9px;
  }
  .footer-holder {
    // padding-top: 7rem;
  }
  .help-holder {
    left: 36%;
    top: 23%;
    position: absolute;
  }
}
@media only screen and (max-width: 599px) {
  // .supper {
  //   min-height: 100%;
  // }
  // .login-body {
  //   padding-top: 4rem;
  // }
  .mobile-logo-holder {
    margin: auto;
    height: 4rem;
    position: relative;
  }
  .blue-back-logo > img {
    width: 18.3016105417vw;
  }
  .shelta-mobile-logo {
    position: absolute;
    top: 5%;
    left: 46%;
  }
  .shelta-mobile-logo > img {
    width: 8.7115666179vw;
  }
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  // .login-body > div {
  //   max-width: 29.02625rem !important;
  // }
  .mobile-logo-holder {
    display: none;
  }
}
</style>
